/** @jsx jsx */

import Img from 'gatsby-image'
import { FC } from 'react'
import { Box, jsx, Container, Text } from 'theme-ui'

import Halo from '../components/halo'
import Intro from '../components/intro'
import Layout from '../components/layout'
import Navigation from '../components/navig'

export interface TermsPageProps {
  // data: {
  //   file: {
  //     childImageSharp: { fluid: any }
  //   }
  // }
}

const TermsPage: FC<TermsPageProps> = (
  {
    // data: {
    //   file: {
    //     childImageSharp: { fluid },
    //   },
    // },
  },
) => {
  return (
    <Layout>
      <Navigation alt={false} />
      <Box variant="box.plateBig" sx={{ px: 4 }}>
        <Halo title="Terms | Functonary" url="https://nft.rage.fan/terms" />
        <Container>
          <Box variant="box.plateMedium2" sx={{ color: 'text', pt: 7, pb: 6 }}>
            <Intro title="Terms for Functonary" description="" />
            <Text as="p" variant="body" sx={{ color: 'grays.9', fontWeight: 100, fontSize: 3 }}></Text>
            <Box sx={{ maxWidth: '70ch', fontSize: 1, color: 'grays.6'}}>
              <p>
                At Functonary Website, accessible from http://ragefan.co/privacy, one of our main priorities is the
                privacy of our visitors. This Privacy Policy document contains types of information that is collected
                and recorded by Functonary Website and how we use it.
              </p>
            </Box>
          </Box>
        </Container>
      </Box>
    </Layout>
  )
}

export default TermsPage